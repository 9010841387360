import unityhousevideo from './videos/UnityHouse.mp4';
import logo from './3DVR.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Check if the Stripe script is already loaded
    const existingScript = document.querySelector('script[src="https://js.stripe.com/v3/buy-button.js"]');

    if (!existingScript) {
      // Dynamically load Stripe script
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/buy-button.js';
      script.async = true;
      document.body.appendChild(script);

      // Create the Stripe Buy Button after script is loaded
      script.onload = () => {
        // Check if the Stripe button is already present
        const existingButton = document.querySelector('stripe-buy-button');
        if (!existingButton) {
          const stripeButton = document.createElement('stripe-buy-button');
          stripeButton.setAttribute('buy-button-id', 'buy_btn_1PtwsSGiUl5dM378w1VuwmeH');
          stripeButton.setAttribute('publishable-key', 'pk_live_51NMta0GiUl5dM378kHhNpTahpku5rgrPFy5Wl0L5orXWNDeOxuHgNWFnNb2DuEZNlm2mvaWsYmomigntEccfASMC00xH42k5w0');
          document.getElementById('stripe-button-container').appendChild(stripeButton);
        }
      };
    }

    // Cleanup function to remove the script (if necessary)
    return () => {
      const existingScript = document.querySelector('script[src="https://js.stripe.com/v3/buy-button.js"]');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header"></header>

      <body className="App-body">
        <div className="topnav" id="myTopnav">
          <a href="#home">3dvr.tech</a>
          <a href="#home">Home</a>
          <a href="#what-we-do-section">What We Do</a>
          <a href="#projects-section">Projects</a>
          <a href="#biography-section">Biography</a>
          <a href="#home">
            <img src={logo} className="nav-logo" alt="logo" />
          </a>
        </div>

        <div className="home-section" id="home">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="Productions">Productions</p>
          <p className="Catch-phrase">Build The Future</p>
        </div>

        <div className="introduction-section">
          <h2 className="we-are-a">We are an emerging technology company</h2>
          <h2 className="creating-universal">Creating Universal Experiences</h2>
        </div>

        {/* Stripe Buy Button Integration */}
        <div id="stripe-button-container"></div> {/* Stripe Button will be inserted here */}

        <div className="tool-section">
          <Row className="d-flex align-items-center">
            <Col>
              <h2 className="using-free">Using the latest Tools</h2>
            </Col>
            <Col>
              <ul className="tool-list">
                <li>Unity</li>
                <li className="three-tool-list">Three.js</li>
                <li>React</li>
              </ul>
            </Col>
          </Row>
        </div>

        <div className="plan-section">
          <h2 className="creating-environments">Developing Environments to Explore</h2>
        </div>

        {/* What We Do Section */}
        <div id="what-we-do-section" className="what-we-do-section">
          <div className="container">
            <div className="row">
              <h1 className="what-we-do-title">What We Do</h1>
            </div>
            <div className="row">
              <div className="col-md what-we-do-column-formatting">
                <h2>3d Design</h2>
                <p><em>Dream up virtual objects.</em></p>
                <h4>
                  <a href="https://www.maxon.net/en/cinema-4d" className="plain-link" target="_blank" rel="noreferrer">
                    Cinema 4d
                  </a>
                </h4>
                <h4>
                  <a href="https://www.blender.org/about/" className="plain-link" target="_blank" rel="noreferrer">
                    Blender
                  </a>
                </h4>
                <h4>
                  <a href="https://www.gravitysketch.com/" className="plain-link" target="_blank" rel="noreferrer">
                    Gravity Sketch
                  </a>
                </h4>
              </div>

              <div className="col-md what-we-do-column-formatting">
                <h2>Web App Integration</h2>
                <p><em>Showcase using the web</em></p>
                <h4>
                  <a href="https://threejs.org/examples/#webgl_animation_keyframes" className="plain-link" target="_blank" rel="noreferrer">
                    three.js
                  </a>
                </h4>
                <h4>
                  <a href="https://aframe.io/" className="plain-link" target="_blank" rel="noreferrer">
                    A-frame
                  </a>
                </h4>
                <h4>
                  <a href="https://docs.pmnd.rs/react-three-fiber/getting-started/introduction" className="plain-link" target="_blank" rel="noreferrer">
                    React Three Fiber
                  </a>
                </h4>
              </div>

              <div className="col-md what-we-do-column-formatting">
                <h2>Metaverse Worlds</h2>
                <p><em>Create immersive environments.</em></p>
                <h4>
                  <a href="https://unity.com/" className="plain-link" target="_blank" rel="noreferrer">
                    Unity 3d
                  </a>
                </h4>
                <h4>
                  <a href="https://altvr.com/" className="plain-link" target="_blank" rel="noreferrer">
                    Altspace Worlds
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/* Projects Section */}
        <div className="projects-section" id="projects-section">
          <div className="container">
            <div className="row">
              <h1 className="z-index">Projects</h1>

              <div className="col z-index projects-sections">
                <h2>
                  <a href="https://account.altvr.com/worlds/1811899537686528860/spaces/1829292561525637486" className="plain-link" target="_blank" rel="noreferrer">
                    House in Unity
                  </a>
                </h2>
                <p>This is a House we made using Unity3D hosted on AlspaceVR.</p>
                <video preload="none" autoPlay="autoplay" id="video" muted playsInline loop>
                  <source src={unityhousevideo} type="video/mp4" />
                  Your Browser Does not support this video
                </video>
              </div>

              <div className="col z-index projects-sections">
                <h2>Gif English Center</h2>
                <p>
                  Gif English Center is a remarkable place in Guatemala where people of all ages can learn English. We are helping them build an online language learning platform.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Biography Section */}
        <div className="biography-section" id="biography-section">
          <Container>
            <Row>
              <h1 className="biography-title">Biography</h1>
            </Row>
            <Row>
              <Col>
                {/* Thomas Biography */}
                <div className="thomas-biography">
                  <h2 className="thomas-biography-name">Thomas M. Stephens</h2>
                  <p className="thomas-biography-paragraph">
                    Thomas has been traveling the globe; seeking spirituality and putting on world-class events.
                  </p>
                  <p className="thomas-biography-paragraph">
                    Ready to build a new world of technology that is free from borders and boundaries.
                  </p>
                  <p className="thomas-biography-paragraph">
                    With a background in music, live events, and computer programming; he is ready to capture the details that make any experience relatable, believable, and polished.
                  </p>
                </div>
              </Col>

              <Col>
                {/* David Biography */}
                <div className="david-biography">
                  <h2 className="david-biography-name">David Martínez R.</h2>
                  <p className="david-biography-paragraph">
                    David has been working as a designer in the fields of web design, 3D modeling, and animation.
                  </p>
                  <p className="david-biography-paragraph">
                    He is excited to take the next steps into virtual reality.
                  </p>
                  <p className="david-biography-paragraph">
                    Creative, visionary, and artist; David has a passion for learning new technologies.
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {/* Button Willow Biography */}
                <div className="button-biography">
                  <h2 className="button-biography-name">Button Willow</h2>
                  <p className="button-biography-paragraph">
                    Button Willow is a dedicated and skilled member of the team, focusing on technology and design. 
                  </p>
                  <p className="button-biography-paragraph">
                    Passionate about creating immersive digital experiences, Button is always looking for ways to push the boundaries of what's possible in virtual and augmented reality.
                  </p>
                  <p className="button-biography-paragraph">
                    With an eye for detail and a drive for perfection, Button is an invaluable contributor to the vision of 3DVR.Tech.
                  </p>
                </div>
              </Col>

              <Col>
                {/* Brandon Adolfo Biography */}
                <div className="brandon-biography">
                  <h2 className="brandon-biography-name">Brandon Adolfo</h2>
                  <p className="brandon-biography-paragraph">
                    Brandon Adolfo is a visionary entrepreneur and designer with a strong background in app development.
                  </p>
                  <p className="brandon-biography-paragraph">
                    He is passionate about building tools that help people connect and collaborate in new and innovative ways.
                  </p>
                  <p className="brandon-biography-paragraph">
                    As a key member of 3DVR.Tech, Brandon’s forward-thinking approach helps drive the company toward new heights in both technology and design.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </body>
    </div>
  );
}

export default App;
